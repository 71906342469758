import Swiper  from 'swiper/bundle';
import 'swiper/css/bundle';

const swiper = new Swiper('.swiper', {
    //navigation: {nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev'},
    pagination: {el: '.swiper-pagination', clickable: true},
    autoplay: {delay: 5000},
    breakpoints: {
        1300: { // not in sync with CSS.
            enabled: false,
            slidesPerView: 3,
        }
    }
});

console.log("Developed by Jacopo Gargiulo.");

